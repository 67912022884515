import Header from "./Header"
import Footer from "./Footer"

function MainLayout(props) {
  const { children } = props
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default MainLayout
