import React from "react"
import { AnimationOnScroll } from "react-animation-on-scroll"
import styled from "styled-components"

const StyledCard = styled.div`
  overflow: hidden;
  margin: 12px;
  padding: 0;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.pinterestBg};
  grid-row-end: ${({ height }) => height};
`

function Card(props) {
  const { data, height, index } = props

  return (
    <StyledCard height={height}>
      <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={30 * index} offset={10}>
        {data}
      </AnimationOnScroll>
    </StyledCard>
  )
}

export default Card
