import { useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { THEME_STYLE } from "../constants"
import { useGlobalContext } from "../GlobalContext"
import { lightTheme, darkTheme, GlobalStyles } from "../theme"

function ThemeProviderWrapper(props) {
  const { theme, toggleTheme } = useGlobalContext()
  const { children } = props
  const isDarkTheme = theme === THEME_STYLE.dark

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme")
    if (savedTheme && [THEME_STYLE.dark, THEME_STYLE.light].includes(savedTheme)) {
      toggleTheme(savedTheme)
    }
  }, [])
  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default ThemeProviderWrapper
