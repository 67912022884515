import { AnimationOnScroll } from "react-animation-on-scroll"
import styled from "styled-components"
import { BREAKPOINTS } from "../constants"

const PADDING = "5px 0"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 0px;
  align-items: center;
  margin-bottom: 120px;

  @media (max-width: ${BREAKPOINTS.xs}) {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;

    &:nth-child(odd) {
      div:nth-child(1) {
        padding: ${PADDING};
        order: 2;
        text-align: center;
      }
    }

    &:nth-child(even) {
      div:nth-child(1) {
        order: 1;
        padding: ${PADDING};
        text-align: center;
      }
      div:nth-child(2) {
        order: 2;
      }
    }
  }
`

const WrapperCol = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`

const InnerWrapper = styled.div`
  max-width: 530px;
  max-height: 480px;
  overflow: ${({ step }) => (step ? "inherit" : "hidden")};
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    z-index: 2;
    position: relative;
    max-width: 520px;

    @media (max-width: ${BREAKPOINTS.xs}) {
      padding-bottom: 32px;
      text-align: center;
    }
  }

  @media (max-width: ${BREAKPOINTS.xs}) {
    max-width: 100%;
    max-height: 550px;
  }
`

const Circle = styled.div`
  background-color: ${({ theme }) => theme.grid1Bg};
  position: absolute;
  width: 368px;
  height: 368px;
  border-radius: 50%;
  left: -155px;
  top: -110px;
  z-index: 1;

  @media (max-width: ${BREAKPOINTS.xs}) {
    width: 190px;
    height: 190px;
    left: -44px;
    top: -59px;
  }
`

const StyledCircle = styled(Circle)`
  background-color: ${({ theme }) => theme.grid4Bg};
  right: -90px;
  left: auto;
  width: 240px;
  height: 240px;
  top: -90px;

  @media (max-width: ${BREAKPOINTS.xs}) {
    display: none;
  }
`

function TwoColumns({ items }) {
  return items.map((item, index) => (
    <Wrapper key={item.key} className={item.key}>
      <WrapperCol>
        <div style={{ position: "relative" }}>
          <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={100}>
            {item.key === "one" && <Circle />}
            <InnerWrapper step={item.key === "three" ? "three" : null}>
              {index % 2 === 0 ? item.one : item.two}
            </InnerWrapper>
          </AnimationOnScroll>
        </div>
      </WrapperCol>
      <WrapperCol>
        <div style={{ position: "relative" }}>
          <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={300}>
            {item.key === "four" && <StyledCircle />}
            <InnerWrapper>{index % 2 === 0 ? item.two : item.one}</InnerWrapper>
          </AnimationOnScroll>
        </div>
      </WrapperCol>
    </Wrapper>
  ))
}

export default TwoColumns
