import React from "react"
import styled from "styled-components"
import { Formik, Form, Field, useFormik } from "formik"
import { useTranslation } from "react-i18next"

import { ReactComponent as ArrowRight } from "../assets/images/arrow-right.svg"
import { BREAKPOINTS } from "../constants"
import StyledButton from "./StyledButton"
import { useGlobalContext } from "../GlobalContext"

const RADIUS = "45px"
const HEIGHT = "60px"
const INPUT = "500px"

const CustomForm = styled.div`
  display: flex;
  position: relative;
  margin-top: 20px !important;

  @media (max-width: ${BREAKPOINTS.xs}) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  input {
    max-width: 500px;
    width: 100%;
    height: ${HEIGHT};
    padding-left: 22px;
    border-radius: ${RADIUS};
    border: 1px solid #cacaca;
    outline: 0px solid #cacaca;

    &::placeholder {
      color: #000;
    }

    @media (max-width: ${BREAKPOINTS.xs}) {
      border-radius: 12px;
      height: 54px;
    }
  }
`

const Submit = styled.button`
  width: 52px;
  height: 52px;
  margin-left: -58px;
  margin-top: 4px;
  background: #6d6aff;
  border: 1px solid #6d6aff;
  border-radius: ${RADIUS};

  @media (max-width: ${BREAKPOINTS.xs}) {
    display: none;
  }
`

const ButtonWrapper = styled.div`
  max-width: ${INPUT};
  width: 100%;

  @media (min-width: ${BREAKPOINTS.xs}) {
    display: none;
  }

  button {
    margin-top: 12px;
    max-width: 100%;
    font-size: 16px;
    border-radius: 12px;

    svg {
      margin-left: 12px;
      height: 16px;
    }
  }
`

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

const Error = styled.div`
  position: absolute;
  color: ${(props) => props.theme.error};
  top: -18px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  max-width: ${INPUT};

  @media (max-width: ${BREAKPOINTS.xs}) {
    max-width: 100%;
  }
`

function validateEmail(value) {
  let error

  if (!value) {
    error = "Please provide an email"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address"
  }

  return error
}

function Subscribe() {
  const { t } = useTranslation()
  const { handleShow, setEmail } = useGlobalContext()

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={(values, { resetForm }) => {
        setEmail(values.email)
        handleShow()
        resetForm()
      }}
    >
      {({ errors, touched, isValidating }) => (
        <Form>
          <CustomForm>
            <InputWrapper>
              <Field name="email" validate={validateEmail} placeholder={t("your_email_address")} />
              <Submit type="submit">
                <ArrowRight onClick={!errors ? () => handleShow() : null} />
              </Submit>
              {errors.email && touched.email && <Error>{errors.email}</Error>}
            </InputWrapper>

            <ButtonWrapper>
              <StyledButton
                onClick={!errors ? () => handleShow() : null}
                text={t("subscribe.button")}
                textColor="#fff"
                bgColor="#5B58F3"
              >
                <ArrowRight />
              </StyledButton>
            </ButtonWrapper>
          </CustomForm>
        </Form>
      )}
    </Formik>
  )
}

export default Subscribe
