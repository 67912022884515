import React from "react"
import styled from "styled-components"

const StyledB = styled.button`
  padding: 15px 0px;
  max-width: 353px;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0 auto;
  width: 100%;
`

function StyledButton(props) {
  const { text, onClick, textColor, bgColor, children } = props

  return (
    <StyledB color={textColor} bg={bgColor} onClick={onClick}>
      {text}
      {children}
    </StyledB>
  )
}

export default StyledButton
