import { Col } from "react-bootstrap"
import { AnimationOnScroll } from "react-animation-on-scroll"
import styled from "styled-components"
import { BREAKPOINTS } from "../constants"

const StyledCol = styled(Col)`
  margin-top: 72px;
  margin-bottom: 88px;
  color: #fff;

  @media (max-width: ${BREAKPOINTS.xs}) {
    margin-top: 40px;
    margin-bottom: 8px;
  }
`

const MainHeading = styled.div`
  text-align: center;
  margin-bottom: 94px;

  @media (max-width: ${BREAKPOINTS.xs}) {
    margin-bottom: 40px;
  }

  h2 {
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 24px;
    margin: 0;

    @media (max-width: ${BREAKPOINTS.xs}) {
      font-size: 24px;
      font-weight: 500;
    }
  }

  span {
    font-size: 20px;
    font-weight: 400;
    max-width: 540px;
    display: inline-block;

    @media (max-width: ${BREAKPOINTS.xs}) {
      font-size: 18px;
      font-weight: 500;
      max-width: 100%;
    }
  }
`

const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1, 1fr));
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 80%;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.xs}) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    text-align: center;
  }

  h3 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    max-width: 430px;

    @media (max-width: ${BREAKPOINTS.xs}) {
      max-width: 100%;
      opacity: 0.8;
    }
  }
`

const StyledLi = styled.li`
  margin-bottom: 48px;
  padding-left: 15%;

  @media (max-width: 1440px) {
    padding-left: 0;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    padding-left: 0;
  }

  @media (max-width: ${BREAKPOINTS.xs}) {
    margin-bottom: 32px;
    padding-left: 0;
  }
`

function Examples({ data, title, subTitle }) {
  return (
    <StyledCol>
      <MainHeading>
        <AnimationOnScroll animateOnce animateIn="animate__fadeIn">
          {title && <h2>{title}</h2>}
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={200}>
          {subTitle && <span>{subTitle}</span>}
        </AnimationOnScroll>
      </MainHeading>
      <Wrapper>
        {data.map((el, i) => (
          <AnimationOnScroll key={el.title} animateOnce animateIn="animate__fadeIn" delay={150 * i}>
            <StyledLi>
              <h3>{el.title}</h3>
              <p>{el.subTitle}</p>
            </StyledLi>
          </AnimationOnScroll>
        ))}
      </Wrapper>
    </StyledCol>
  )
}

export default Examples
