import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Card from "./Card"

import image1 from "../assets/images/1.png"
import image2 from "../assets/images/3.png"
import image3 from "../assets/images/5.png"
import image5 from "../assets/images/8.png"
import image7 from "../assets/images/4.png"
import image9 from "../assets/images/12.png"
import image10 from "../assets/images/11.png"
import image11 from "../assets/images/9.png"
import { BREAKPOINTS } from "../constants"

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: ${BREAKPOINTS.xs}) {
    object-position: center;
  }
`

const TextCard = styled.div`
  font-size: 24px;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  padding: 28px;
  height: ${({ height }) => height};
  line-height: 30px;

  @media (max-width: ${BREAKPOINTS.xs}) {
    font-size: 16px;
    padding: 16px;
  }

  @media (max-width: 550px) {
    line-height: inherit;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }

  @media (max-width: 400px) {
    font-size: 13px;
    line-height: 14px;
  }
`

const StyledPinterestLayout = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 354px);
  grid-auto-rows: 10px;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.xs}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-auto-rows: 7px;
  }

  @media (max-width: 400px) {
    grid-auto-rows: 5px;
  }
`

function PinterestLayout() {
  const { t } = useTranslation()

  const data = [
    {
      key: "1",
      height: "span 33",
      data: <StyledImage src={image1} alt="logo" />,
    },
    {
      key: "2",
      height: "span 51",
      data: <StyledImage src={image2} alt="logo" />,
    },
    {
      key: "3",
      height: "span 26",
      data: <StyledImage src={image3} alt="logo" />,
    },
    {
      key: "4",
      height: "span 23",
      data: (
        <TextCard bgColor="#8952ff" color="#fff" height="220px">
          {t("pinterest.text_card_1")}
        </TextCard>
      ),
    },
    {
      key: "8",
      height: "span 61",
      data: <StyledImage src={image5} alt="logo" />,
    },
    {
      key: "7",
      height: "span 43",
      data: (
        <TextCard bgColor="#5B58F3" color="#fff" height="420px">
          {t("pinterest.text_card_3")}
        </TextCard>
      ),
    },
    {
      key: "5",
      height: "span 44",
      data: (
        <TextCard bgColor="#F354A1" color="#fff" height="420px">
          {t("pinterest.text_card_2")}
        </TextCard>
      ),
    },
    {
      key: "6",
      height: "span 26",
      data: <StyledImage src={image7} alt="logo" />,
    },
    {
      key: "11",
      height: "span 41",
      data: <StyledImage src={image10} alt="logo" />,
    },
    {
      key: "9",
      height: "span 33",
      data: <StyledImage src={image11} alt="logo" />,
    },
    {
      key: "10",
      height: "span 33",
      data: (
        <TextCard bgColor="#CCFFE7" color="#000" height="420px">
          {t("pinterest.text_card_4")}
        </TextCard>
      ),
    },
    {
      key: "12",
      height: "span 26",
      data: <StyledImage src={image9} alt="logo" />,
    },
  ]

  return (
    <StyledPinterestLayout>
      {data.map((card, i) => (
        <Card key={card.key} data={card.data} height={card.height} index={i} />
      ))}
    </StyledPinterestLayout>
  )
}

export default PinterestLayout
