import styled from "styled-components"
import { Navbar, Nav } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Scrollspy from "react-scrollspy"
import Checkbox from "../../components/Checkbox"
import { useGlobalContext } from "../../GlobalContext"
import { BREAKPOINTS, HEADER_HEIGHT, THEME_STYLE } from "../../constants"
import CustomContainer from "./CustomContainer"
import { ReactComponent as Logo } from "../../assets/images/blue-logo.svg"
import StyledButton from "../../components/StyledButton"

const mobileMargin = "48px"

const StyledWrapper = styled.header`
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  z-index: 10;
  position: fixed;
  height: ${HEADER_HEIGHT};

  @media (min-width: ${BREAKPOINTS.sm}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .toggler-icon {
      display: none !important;
    }
  }

  .row {
    background: #fff;
  }
`

const StyledNavbar = styled(Navbar)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    margin-top: 25px;
  }

  ul {
    margin: 0 !important;
    padding: 0 !important;
  }

  .active {
    font-weight: 600;
  }

  a:first-child {
    margin-right: 30px;
    display: block;

    @media (max-width: ${BREAKPOINTS.sm}) {
      margin-right: 0;
    }
  }

  .menu {
    justify-content: center;
    flex: 1;

    ul {
      display: flex;

      @media (max-width: ${BREAKPOINTS.sm}) {
        flex-direction: column;
      }
    }
  }
`

const CheckboxWrapper = styled.div`
  margin-right: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  span {
    padding-right: 10px;
    color: #000;
  }

  @media (min-width: ${BREAKPOINTS.sm}) {
    display: flex;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    margin-right: 0;
    margin-bottom: ${mobileMargin};
  }
`

const CheckboxWrapperMobile = styled(CheckboxWrapper)`
  display: none;
  margin: 0;

  @media (max-width: ${BREAKPOINTS.sm}) {
    display: flex;
    position: absolute;
    right: 70px;
    top: 5px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 180px;
    height: 49px;
    font-size: 16px;

    @media (max-width: ${BREAKPOINTS.sm}) {
      margin-bottom: ${mobileMargin};
    }
  }
`

const StyledAnchor = styled.a`
  font-size: 16px;
  color: #000;
  text-decoration: none;
  margin-right: 47px;

  @media (max-width: ${BREAKPOINTS.sm}) {
    margin-right: 0;
    margin-bottom: ${mobileMargin};
    text-align: center;

    &:first-child {
      margin-top: ${mobileMargin};
    }
  }
`

function Header() {
  const { theme, handleShow, toggleTheme } = useGlobalContext()
  const { t } = useTranslation()

  const checked = theme === THEME_STYLE.dark
  const toggleThemeColor = (e) => {
    if (e.target.checked) {
      toggleTheme(THEME_STYLE.dark)
    } else {
      toggleTheme(THEME_STYLE.light)
    }
  }

  const checkboxText = theme === "light" ? t("enter_crypto_mode") : t("exit_crypto_mode")
  return (
    <StyledWrapper>
      <CustomContainer>
        <StyledNavbar expand="lg">
          <a href="/" style={{ maxWidth: "180px", width: "100%" }}>
            <Logo />
          </a>
          <CheckboxWrapperMobile>
            <Checkbox onChange={toggleThemeColor} checked={checked} />
          </CheckboxWrapperMobile>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="d-flex"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="toggler-icon top-bar" />
            <span className="toggler-icon middle-bar" />
            <span className="toggler-icon bottom-bar" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto menu">
              <Scrollspy items={["shoppers", "creators", "brands"]} currentClassName="active" offset={1}>
                <StyledAnchor href="#shoppers">{t("mainMenu.shoppers")}</StyledAnchor>
                <StyledAnchor href="#creators">{t("mainMenu.creators")}</StyledAnchor>
                <StyledAnchor href="#brands">{t("mainMenu.brands")}</StyledAnchor>
              </Scrollspy>
            </Nav>
            <Nav className="mr-auto buttons">
              <CheckboxWrapper>
                <span>{checkboxText}</span>
                <Checkbox onChange={toggleThemeColor} checked={checked} />
              </CheckboxWrapper>
              <ButtonWrapper>
                <StyledButton
                  onClick={() => handleShow()}
                  text={t("subscribe.button")}
                  textColor="#fff"
                  bgColor="#5B58F3"
                />
              </ButtonWrapper>
            </Nav>
          </Navbar.Collapse>
        </StyledNavbar>
      </CustomContainer>
    </StyledWrapper>
  )
}

export default Header
