import styled from "styled-components"
import { Container, Row } from "react-bootstrap"
import { PAGE_WIDTH } from "../../constants"

const StyledContainer = styled(Container)`
  max-width: ${PAGE_WIDTH};

  @media (max-width: 1435px) {
    overflow: auto;
  }
`

function CustomContainer({ children }) {
  return (
    <StyledContainer>
      <Row>{children}</Row>
    </StyledContainer>
  )
}

export default CustomContainer
