import { BrowserRouter } from "react-router-dom"
import "./i18n/config"
import "bootstrap/dist/css/bootstrap.min.css"
import { GlobalContextProvider } from "./GlobalContext"
import ThemeProviderWrapper from "./components/ThemeProviderWrapper"
import AppRoutes from "./Routes"

function App() {
  return (
    <div className="App">
      <GlobalContextProvider>
        <ThemeProviderWrapper>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </ThemeProviderWrapper>
      </GlobalContextProvider>
    </div>
  )
}

export default App
