import { useEffect, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { AnimationOnScroll } from "react-animation-on-scroll"
import { BREAKPOINTS } from "../constants"
import { ReactComponent as Check } from "../assets/images/check.svg"
import check from "../assets/images/tick.svg"

const singleStepAnimation = 600
const renderingWaitDelay = 200

const alignment = 15

const Wrapper = styled.div`
  margin-top: 120px;
  margin-bottom: 110px;

  @media (max-width: ${BREAKPOINTS.xs}) {
    margin-top: 69px;
    margin-bottom: 64px;
  }
`

const ProgressBarWrapper = styled.div`
  @media (max-width: ${BREAKPOINTS.xs}) {
    width: 800px;
    overflow-x: auto;
    overflow-y: hidden;
  }
`

const StyledTitle = styled.h2`
  text-align: center;
  padding-bottom: 60px;
  color: ${({ theme }) => theme.stepsTitle};
  font-weight: 600;
  font-size: 32px;

  @media (max-width: ${BREAKPOINTS.xs}) {
    padding-bottom: 40px;
    font-size: 28px;
    font-weight: 700;
  }
`

const StatusBar = styled.div`
  visibility: hidden;
  height: 2px;
  background: ${({ theme }) => theme.stepsProgressBarBg};
  position: relative;
  top: calc(${alignment}px + 22px);
  margin: 0 auto;
  width: ${({ width }) => width};

  ${({ show }) =>
    show &&
    `
  visibility: visible;
`}
`

const CurrentStatus = styled.div`
  height: 2px;
  width: 0;
  border-radius: 1px;
  background: ${({ theme }) => theme.stepsinProgressBg};
  width: ${({ width }) => width};
  transition: ${({ transition }) => transition};
`

const CheckpointsWrapper = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  list-style: none;
`

const Section = styled.li`
  visibility: hidden;
  display: inline-block;
  padding-top: 90px;
  font-size: 16px;
  color: ${({ theme }) => theme.title};
  vertical-align: top;
  position: relative;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ width }) => width};
  animation: fadeIn 1s;

  ${({ show }) =>
    show &&
    `
    visibility: visible;
  `}

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  div {
    max-width: 239px;
    margin: 0 auto;
    opacity: 0.8;
  }

  &:before {
    content: ${({ content }) => content};
    position: absolute;
    top: 2px;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    border: 2px solid ${({ theme }) => theme.stepsBg};
    border-radius: 20px;
    line-height: 30px;
    background: ${({ theme }) => theme.stepsCircleBg};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${alignment}px;
  }

  &:after {
    content: ${({ content }) => content};
    position: absolute;
    top: -2px;
    left: calc(50% - 24px);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 4px solid ${({ theme }) => theme.stepsBg};
    line-height: 30px;
    background: ${({ theme }) => theme.stepsCircleBg};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${alignment}px;
  }

  &.visited.current div {
    opacity: 1;
  }

  &.visited:before,
  &.visited:after {
    content: url(${({ position }) => (position ? check : "")});
    margin-top: ${alignment}px;
  }

  &.visited.current:before {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.stepsCircleCurrentBg};
    z-index: 3;
    margin-top: ${alignment}px;
    background: ${({ theme }) => theme.stepsCircleCurrentBg};
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: transparent;
    top: -14px;
    left: calc(50% - 36px);
  }

  &.visited.current:after {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.stepsCircleCurrentBg};
    background: ${({ theme }) => theme.stepsCircleCurrentBg};
    z-index: 2;
    margin-top: ${alignment}px;
  }
`

function ProgressBar({ stages, stageWidth, currentStageIndex, show }) {
  const [width, setWidth] = useState()
  const [transition, setTransition] = useState()
  useEffect(() => {
    requestAnimationFrame(() => {
      // eslint-disable-next-line func-names
      setTimeout(function () {
        setWidth(`${(100 * currentStageIndex) / (stages.length - 1)}%`)
        setTransition(`width ${currentStageIndex * singleStepAnimation}ms linear`)
      }, renderingWaitDelay)
    })
  }, [])
  return (
    <StatusBar width={`${100 - stageWidth}%`} show={show}>
      <CurrentStatus width={width} transition={transition} />
    </StatusBar>
  )
}

function Checkpoint({ stage, stageWidth, currentStageIndex, index, show }) {
  const [className, setClassName] = useState("")
  useEffect(() => {
    requestAnimationFrame(() => {
      // eslint-disable-next-line func-names
      setTimeout(function () {
        if (currentStageIndex > index) {
          setClassName("visited")
        } else if (currentStageIndex === index) {
          setClassName("visited current")
        }
      }, renderingWaitDelay + index * singleStepAnimation + 100)
    })
  }, [])
  return (
    <Section
      className={className}
      width={`${stageWidth}%`}
      content={`''`}
      position={currentStageIndex > index}
      show={show}
    >
      <div>{stage}</div>
    </Section>
  )
}

function Checkpoints({ stages, stageWidth, currentStageIndex, show }) {
  return (
    <CheckpointsWrapper>
      {stages.map((stage, index) => (
        <Checkpoint
          key={stage}
          stage={stage}
          index={index}
          stageWidth={stageWidth}
          currentStageIndex={currentStageIndex}
          show={show}
        >
          <Check />
        </Checkpoint>
      ))}
    </CheckpointsWrapper>
  )
}

function Steps({ title }) {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  const stages = [t("steps.step_1"), t("steps.step_2"), t("steps.step_3"), t("steps.step_4"), t("steps.step_5")]
  const currentStage = stages[1]

  const stageWidth = 100 / stages.length
  const currentStageIndex = stages.indexOf(currentStage)

  return (
    <Wrapper>
      <AnimationOnScroll animateOnce animateIn="animate__fadeIn">
        {title && <StyledTitle>{title}</StyledTitle>}
      </AnimationOnScroll>
      <AnimationOnScroll animateOnce animateIn="animate__fadeIn" afterAnimatedIn={() => setShow(true)} duration={0.5}>
        <ProgressBarWrapper key={show}>
          <ProgressBar stages={stages} stageWidth={stageWidth} currentStageIndex={currentStageIndex} show={show} />
          <Checkpoints stages={stages} stageWidth={stageWidth} currentStageIndex={currentStageIndex} show={show} />
        </ProgressBarWrapper>
      </AnimationOnScroll>
    </Wrapper>
  )
}

export default Steps
