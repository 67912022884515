import styled from "styled-components"

const Label = styled.label`
  position: relative;
  display: inline-block;
  height: 34px;
  width: 60px;

  input {
    display: none;

    &:checked + .slider:before {
      transform: translateX(26px);
    }

    &:checked + .slider {
      background-color: ${({ theme }) => theme.sliderBg};
    }
  }

  .slider {
    background-color: ${({ theme }) => theme.sliderBg};
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.2s;

    &:before {
      background-color: ${({ theme }) => theme.slider};
      bottom: 4px;
      content: "";
      height: 26px;
      left: 4px;
      position: absolute;
      transition: 0.4s;
      width: 26px;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
`

function Checkbox({ onChange, checked }) {
  return (
    <Label>
      <input type="checkbox" id="checkbox" onChange={onChange} defaultChecked={checked} />
      <div className="slider round" />
    </Label>
  )
}

export default Checkbox
