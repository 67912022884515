export const URLS = {
  home: "/",
  shoppers: "/shoppers",
  creators: "/creators",
  brands: "/brands",
}

export const THEME_STYLE = {
  light: "light",
  dark: "dark",
}

export const PAGE_WIDTH = "1443px"

export const HEADER_HEIGHT = "100px"

export const BREAKPOINTS = {
  xs: "768px",
  sm: "992px",
  md: "1200px",
}
