import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
`

export const lightTheme = {
  black: "#000",
  body: "#fff",
  text: "#121620",
  purple: "#dfbfff",
  headerBgColor: "#fff",
  sliderBg: "#DEE1EC",
  slider: "#fff",
  mainText: "#F14698",
  pinterestBg: "#F9ECAB",
  pinterestGradient: "#F9ECAB",
  gridBg: "#F5F5F5",
  gridParagraph: "#222222",
  gridTitle1: "#5b58f3",
  gridTitle2: "#f354a0",
  gridTitle3: "#ff7d34",
  gridTitle4: "#ac6aff",
  gridTitle5: "#23ca9d",
  grid1Bg: "#E2DFFF",
  grid4Bg: "#F1D3FF",
  examplesBg: "#3F35A3",
  stepsBg: "#F5F5F5",
  stepsTitle: "#000",
  stepsCircleBg: "#B4B9CA",
  stepsCircleCurrentBg: "#6F6CFF",
  stepsProgressBarBg: "#D8DCE9",
  stepsinProgressBg: "#4C4B6C",
  modalInputBg: "#f5f5f5",
  modalInputPlaceholder: "#c5c5c5",
  error: "#000",
}

export const darkTheme = {
  black: "#000",
  body: "#121620",
  text: "#f1f1f1",
  purple: "#dfbfff",
  headerBgColor: "#33378c",
  sliderBg: "#5B58F3",
  slider: "#fff",
  mainText: "#FF84B0",
  pinterestBg: "#151844",
  pinterestGradient: "#0e0e0e",
  gridBg: "#1F1E3D",
  gridParagraph: "#C3C5E4",
  gridTitle1: "#6f6cff",
  gridTitle2: "#f354a0",
  gridTitle3: "#ff7d34",
  gridTitle4: "#ac6aff",
  gridTitle5: "#23ca9d",
  grid1Bg: "#393464",
  grid4Bg: "#F1D3FF",
  examplesBg: "#3F35A3",
  stepsBg: "#1F1E3D",
  stepsTitle: "#fff",
  stepsCircleBg: "#393B5F",
  stepsCircleCurrentBg: "#5B58F3",
  stepsProgressBarBg: "#4C4B6C",
  stepsinProgressBg: "#5B58F3",
  modalInputBg: "#f5f5f5",
  modalInputPlaceholder: "#c5c5c5",
  error: "#fff",
}
