import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import { AnimationOnScroll } from "react-animation-on-scroll"
import Scrollspy from "react-scrollspy"
import CustomContainer from "./CustomContainer"

import { ReactComponent as WhiteLogo } from "../../assets/images/white-logo.svg"
import { ReactComponent as TikTok } from "../../assets/images/tiktok.svg"
import { ReactComponent as Envelope } from "../../assets/images/envelope.svg"
import { ReactComponent as Medium } from "../../assets/images/medium.svg"
import { ReactComponent as Building } from "../../assets/images/building.svg"
import { BREAKPOINTS } from "../../constants"
import { useGlobalContext } from "../../GlobalContext"

const Heading = styled.h4`
  color: #f6f6f6;
  font-size: 18px;
  padding-bottom: 24px;
`

const Shared = css`
  a {
    color: #d6d6d6;
    text-decoration: none;
    font-size: 16px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;

    @media (max-width: ${BREAKPOINTS.xs}) {
      justify-content: center;
    }

    &:last-child {
      padding-bottom: 0;
    }

    svg {
      padding-right: 10px;
      max-width: 28px;
      width: 100%;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    padding-right: 20%;

    @media (max-width: ${BREAKPOINTS.xs}) {
      &:last-child {
        padding-right: 0;
        text-align: center;
      }
    }
  }
`

const FooterWrapper = styled.div`
  background-color: #000;
  color: #fff;

  padding-top: 56px;
  padding-bottom: 49px;

  @media (max-width: ${BREAKPOINTS.xs}) {
    padding-top: 32px;
  }
`

const Wrapper = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.xs}) {
    flex-direction: column;
  }
`

const LogoSection = styled.section`
  color: #b9b9b9;
  max-width: 593px;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.xs}) {
    text-align: center;
    margin-bottom: 56px;
    max-width: 100%;
  }

  a {
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }
  }

  svg {
    padding-bottom: 24px;
    height: 45px;
  }

  p {
    font-size: 16px;
    padding-bottom: 28px;
    max-width: 360px;

    @media (max-width: ${BREAKPOINTS.xs}) {
      max-width: 100%;
    }
  }

  span {
    font-size: 14px;
  }
`

const CompanyLegalSection = styled.section`
  display: flex;
  justify-content: space-around;
  width: 100%;

  ul {
    padding-left: 0;
  }

  @media (max-width: ${BREAKPOINTS.xs}) {
    justify-content: center;
    padding-bottom: 48px;
    max-width: 100%;
  }

  ${Shared}
`

const ContactUs = styled.section`
  max-width: 250px;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.xs}) {
    text-align: center;
    padding-left: 0;
    max-width: 100%;
  }

  ${Shared}

  div {
    padding-right: 0;
  }
`

function Footer() {
  const { t } = useTranslation()
  const { handleShow } = useGlobalContext()

  const onClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    handleShow()
  }
  return (
    <FooterWrapper>
      <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={200}>
        <CustomContainer>
          <Wrapper>
            <LogoSection>
              <WhiteLogo />
              <p>{t("footer.logo_section")}</p>
              <div>
                <a href="https://medium.com/@clovis.clove">
                  <Medium />
                </a>
                <a href="https://www.tiktok.com/@s3xpool ">
                  <TikTok />
                </a>
              </div>
              <span>{t("footer.logo_reserved")}</span>
            </LogoSection>
            <CompanyLegalSection>
              <div>
                <Heading>{t("footer.company.company")}</Heading>
                <Scrollspy items={["shoppers", "creators", "brands"]} currentClassName="active" offset={1}>
                  <a href="#shoppers">{t("mainMenu.shoppers")}</a>
                  <a href="#creators">{t("mainMenu.creators")}</a>
                  <a href="#brands">{t("mainMenu.brands")}</a>
                  <a href="/" onClick={(e) => onClick(e)}>
                    {t("footer.company.sign_up")}
                  </a>
                </Scrollspy>
              </div>
            </CompanyLegalSection>
            <ContactUs>
              <div>
                <Heading>{t("footer.contact_us.contact_us")}</Heading>
                <a href={`mailto:${t("footer.contact_us.address")}`}>
                  <Envelope />
                  {t("footer.contact_us.email")}
                </a>
                <a href="/">
                  <Building />
                  <span>{t("footer.contact_us.address")}</span>
                </a>
              </div>
            </ContactUs>
          </Wrapper>
        </CustomContainer>
      </AnimationOnScroll>
    </FooterWrapper>
  )
}

export default Footer
