import { createContext, useState, useMemo, useContext } from "react"
import { THEME_STYLE } from "./constants"

const GlobalContext = createContext()

function GlobalContextProvider({ children }) {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || THEME_STYLE.light)
  const toggleTheme = (updatedTheme) => {
    setTheme(updatedTheme)
    localStorage.setItem("theme", updatedTheme)
  }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [email, setEmail] = useState()

  const value = useMemo(
    () => ({
      theme,
      toggleTheme,
      show,
      handleClose,
      handleShow,
      email,
      setEmail,
    }),
    [theme, toggleTheme]
  )

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

const useGlobalContext = () => useContext(GlobalContext)

export { GlobalContextProvider, useGlobalContext }
