import React from "react"
import { render } from "react-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import reportWebVitals from "./reportWebVitals"
import "./index.css"
import "animate.css/animate.min.css"
import App from "./App"

const root = document.getElementById("root")
render(
  <HelmetProvider>
    <React.StrictMode>
      <Helmet titleTemplate="LandingPage | %s" defaultTitle="LandingPage" />
      <App />
    </React.StrictMode>
  </HelmetProvider>,
  root
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
