import styled from "styled-components"
import { useTranslation, Trans } from "react-i18next"
import TypeWriterEffect from "react-typewriter-effect"
import { AnimationOnScroll } from "react-animation-on-scroll"
import { Col } from "react-bootstrap"
import PageMetaTitle from "../../components/PageMetaTitle"
import LanguageButtons from "../../components/LanguageButtons"
import MainLayout from "../partials/MainLayout"
import PinterestLayout from "../../components/PinterestLayout"
import CustomContainer from "../partials/CustomContainer"
import { BREAKPOINTS } from "../../constants"
import Subscribe from "../../components/Subscribe"
import TwoColumns from "../../components/TwoColumns"

import Steps from "../../components/Steps"
import Examples from "../../components/Examples"
import { useGlobalContext } from "../../GlobalContext"
import StyledButton from "../../components/StyledButton"

import HeaderBgLight from "../../assets/images/mask-group-light.png"
import HeaderBgDark from "../../assets/images/mask-group-dark.png"
import DashboardLight from "../../assets/images/dashboard-colored-sidebar-light.png"
import DashboardDark from "../../assets/images/dashboard-colored-sidebar-dark.png"
import MiraBoardInfo from "../../assets/images/mira-boardinfo.png"
import MiraCollaborateLight from "../../assets/images/mira-collaborate-light.png"
import MiraCollaborateDark from "../../assets/images/mira-collaborate-dark.png"
import GroupLight from "../../assets/images/group-light.png"

import GroupDark from "../../assets/images/group-dark.png"

import PhoneMainDark from "../../assets/images/phone-main-dark.png"
import PhoneMainDarkTopLeft from "../../assets/images/phone-main-top-left-dark.png"
import PhoneMainDarkBottomLeft from "../../assets/images/phone-main-bottom-left-dark.png"
import PhoneMainDarkTopRight from "../../assets/images/phone-main-top-right-dark.png"
import PhoneMainDarkBottomRight from "../../assets/images/phone-main-bottom-right-dark.png"

import PhoneMainLightTopLeft from "../../assets/images/phone-main-top-left-light.png"
import PhoneMainLightBottomLeft from "../../assets/images/phone-main-bottom-left-light.png"
import PhoneMainLightTopRight from "../../assets/images/phone-main-top-right-light.png"
import PhoneMainLightBottomRight from "../../assets/images/phone-main-bottom-right-light.png"

import SubscribeModal from "../../components/SubscribeModal"

const HeaderBackground = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => (props.themeVer === "light" ? HeaderBgLight : HeaderBgDark)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.headerBgColor};
  @media (max-width: ${BREAKPOINTS.xs}) {
    text-align: center;
  }

  div:first-child {
    margin-top: -20px;

    @media (max-width: 1434px) {
      margin-top: 0px;
    }
  }

  div span {
    position: relative;

    @media (max-width: ${BREAKPOINTS.xs}) {
      display: block;
    }

    svg {
      position: absolute;
      bottom: 45px;

      @media (max-width: ${BREAKPOINTS.xs}) {
        left: calc(50% - 160px);
        width: 310px;
        bottom: 30px;
      }
    }
  }

  .animated-text {
    margin-top: 60px !important;

    h1 {
      border-bottom: 2px solid ${(props) => props.theme.mainText};
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
      display: flex;
      justify-content: center;
      margin-top: 20px !important;

      h1 {
        text-align: center;
      }
    }
  }

  h1,
  h4 {
    margin: 0;
    display: block;

    @media (max-width: ${BREAKPOINTS.xs}) {
      margin: 0 auto;
      width: 100%;
    }
  }

  h4 {
    max-width: 490px;
  }

  h1 {
    font-size: 48px;
    margin-bottom: 50px;
    font-weight: 700;
    color: ${(props) => props.theme.mainText};

    @media (max-width: ${BREAKPOINTS.xs}) {
      font-size: 35px;
      margin-bottom: 38px;
    }
  }

  h4 {
    font-size: 28px;
    font-weight: 500;

    @media (max-width: ${BREAKPOINTS.xs}) {
      font-size: 24px;
    }
  }

  h4.second {
    padding-top: 16px;
    padding-bottom: 12px;
  }
`

const PinterestBackground = styled.div`
  background-color: ${(props) => props.theme.pinterestBg};
  padding-top: 67px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.xs}) {
    padding-top: 24px;
  }

  h2 {
    font-size: 32px;
    font-weight: 500;
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 75px;

    @media (max-width: ${BREAKPOINTS.xs}) {
      padding-bottom: 32px;
      font-size: 24px;
      max-width: 100%;
    }
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 230px;
    background: linear-gradient(180deg, rgba(14, 14, 14, 0) 0%, ${(props) => props.theme.pinterestGradient} 100%);
    left: 0;
    right: 0;
    z-index: 4;
  }
`

const GridBackground = styled.div`
  background-color: ${(props) => props.theme.gridBg};
  padding-top: 82px;

  @media (max-width: ${BREAKPOINTS.xs}) {
    padding-top: 48px;
    padding-bottom: 0px;
  }

  h2,
  h4 {
    text-align: center;
    margin: 0 auto;
  }

  h2 {
    padding-bottom: 32px;
    font-size: 20px;
    font-weight: 500;
    max-width: 1143px;

    @media (max-width: ${BREAKPOINTS.xs}) {
      font-size: 20px;
      max-width: 100%;
    }
  }

  h4 {
    padding-bottom: 144px;

    font-size: 24px;
    font-weight: 600;
    max-width: 1020px;

    @media (max-width: ${BREAKPOINTS.xs}) {
      padding-bottom: 100px;
      font-size: 20px;
      max-width: 100%;
    }
  }

  h3 {
    padding-bottom: 32px;
  }

  h3,
  h3 p {
    font-size: 48px;
    margin-bottom: 0;
    display: inline-block;
    font-weight: 700;

    @media (max-width: ${BREAKPOINTS.xs}) {
      font-size: 28px;
    }
  }

  p {
    color: ${(props) => props.theme.gridParagraph};
    font-size: 18px;
  }

  .one {
    h3 p {
      color: ${(props) => props.theme.gridTitle1};
    }
  }

  .two {
    h3 p {
      color: ${(props) => props.theme.gridTitle2};
    }
  }

  .three {
    h3 p {
      color: ${(props) => props.theme.gridTitle3};
    }
  }

  .four {
    h3 p {
      color: ${(props) => props.theme.gridTitle4};
    }
  }

  .five {
    h3 p {
      color: ${(props) => props.theme.gridTitle5};
    }
  }
`

const ExamplesBackground = styled.div`
  background-color: ${(props) => props.theme.examplesBg};
`

const StepsBackground = styled.div`
  background-color: ${(props) => props.theme.stepsBg};
`

const SubscribeBackground = styled.div`
  padding-top: 61px;
  padding-bottom: 58px;
  background: linear-gradient(265.41deg, #ac6aff 9.81%, #5b58f3 66.35%);

  @media (max-width: ${BREAKPOINTS.xs}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    max-width: 878px;
    margin: 0 auto;
    padding-bottom: 40px;

    @media (max-width: ${BREAKPOINTS.xs}) {
      font-size: 24px;
      max-width: 100%;
    }
  }
`

const Type = styled.div`
  display: flex;
  align-items: center;
`

const StyledPhoneAnimation = styled.div`
  position: relative;

  .phone-main {
    z-index: 1;
    @media (max-width: ${BREAKPOINTS.xs}) {
      max-height: 400px;
    }
  }

  div:nth-child(1),
  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(4),
  div:nth-child(5) {
    position: absolute;
    max-width: 160px;
    width: 100%;
    height: auto;

    @media (max-width: ${BREAKPOINTS.xs}) {
      max-width: 120px;
    }
  }

  div:nth-child(1) {
    left: -130px;
    z-index: 1;
    top: calc(50% - 138px);

    @media (max-width: ${BREAKPOINTS.xs}) {
      left: -85px;
    }
  }

  div:nth-child(2) {
    left: -90px;
    bottom: calc(50% - 115px);
    z-index: 3;

    @media (max-width: ${BREAKPOINTS.xs}) {
      left: -75px;
    }
  }

  div:nth-child(5) {
    right: -100px;
    z-index: 1;
    top: calc(50% - 100px);

    @media (max-width: ${BREAKPOINTS.xs}) {
      right: -80px;
    }
  }

  div:nth-child(4) {
    right: -135px;
    bottom: calc(50% - 130px);
    z-index: -1;

    @media (max-width: ${BREAKPOINTS.xs}) {
      right: -90px;
    }
  }
`

function Home() {
  const { t } = useTranslation()
  const { theme, show, handleClose, handleShow } = useGlobalContext()

  const twoColumnsData = [
    {
      key: "one",
      one:
        theme === "light" ? (
          <img src={DashboardLight} alt="Dashboard light" />
        ) : (
          <img src={DashboardDark} alt="Dashboard dark" />
        ),
      two: (
        <>
          <h3>
            <Trans>{t("grid.grid_title_1")}</Trans>
          </h3>
          <p>{t("grid.grid_subTitle_1")}</p>
        </>
      ),
    },
    {
      key: "two",
      one: <img src={MiraBoardInfo} alt="Mira Board Info" />,
      two: (
        <>
          <h3>
            <Trans>{t("grid.grid_title_2")}</Trans>
          </h3>
          <p>
            <Trans i18nKey="multiline">{t("grid.grid_subTitle_2")}</Trans>
          </p>
        </>
      ),
    },
    {
      key: "three",
      one:
        theme === "light" ? (
          <StyledPhoneAnimation>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft" delay={100}>
              <img src={PhoneMainLightTopLeft} alt="Phone Light" />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft" delay={300}>
              <img src={PhoneMainLightBottomLeft} alt="Phone Light" />
            </AnimationOnScroll>
            <img className="phone-main" src={PhoneMainDark} alt="Phone Light" />
            <AnimationOnScroll animateOnce animateIn="animate__fadeInRight" delay={400}>
              <img src={PhoneMainLightBottomRight} alt="Phone Light" />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInRight" delay={200}>
              <img src={PhoneMainLightTopRight} alt="Phone Light" />
            </AnimationOnScroll>
          </StyledPhoneAnimation>
        ) : (
          <StyledPhoneAnimation>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft" delay={100}>
              <img src={PhoneMainDarkTopLeft} alt="Phone Dark" />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft" delay={300}>
              <img src={PhoneMainDarkBottomLeft} alt="Phone Dark" />
            </AnimationOnScroll>
            <img className="phone-main" src={PhoneMainDark} alt="Phone Dark" />
            <AnimationOnScroll animateOnce animateIn="animate__fadeInRight" delay={400}>
              <img src={PhoneMainDarkBottomRight} alt="Phone Dark" />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInRight" delay={200}>
              <img src={PhoneMainDarkTopRight} alt="Phone Dark" />
            </AnimationOnScroll>
          </StyledPhoneAnimation>
        ),
      two: (
        <>
          <h3>
            <Trans>{t("grid.grid_title_3")}</Trans>
          </h3>
          <p>{t("grid.grid_subTitle_3")}</p>
        </>
      ),
    },
    {
      key: "four",
      one:
        theme === "light" ? (
          <img src={MiraCollaborateLight} alt="Mira Collaborate light" />
        ) : (
          <img src={MiraCollaborateDark} alt="Mira Collaborate dark" />
        ),
      two: (
        <>
          <h3>
            <Trans>{t("grid.grid_title_4")}</Trans>
          </h3>
          <p>{t("grid.grid_subTitle_4")}</p>
        </>
      ),
    },
    {
      key: "five",
      one: theme === "light" ? <img src={GroupLight} alt="Group light" /> : <img src={GroupDark} alt="Group dark" />,
      two: (
        <>
          <h3>
            <Trans>{t("grid.grid_title_5")}</Trans>
          </h3>
          <p>
            <Trans i18nKey="multiline">{t("grid.grid_subTitle_5")}</Trans>
          </p>
        </>
      ),
    },
  ]

  const examplesData = [
    {
      title: "01",
      subTitle: t("examples.1"),
    },
    {
      title: "02",
      subTitle: t("examples.2"),
    },
    {
      title: "03",
      subTitle: t("examples.3"),
    },
    {
      title: "04",
      subTitle: t("examples.4"),
    },
  ]

  return (
    <MainLayout>
      <PageMetaTitle title={t("mainMenu.home")} />
      <LanguageButtons />
      <HeaderBackground themeVer={theme}>
        <CustomContainer>
          <AnimationOnScroll animateOnce animateIn="animate__fadeIn">
            <div>
              <AnimationOnScroll animateOnce animateIn="animate__fadeIn">
                <h4>{t("header.text_1")}</h4>
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={200}>
                <h4 className="second">{t("header.text_2")}</h4>
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={400}>
                <div className="animated-text">
                  <Type>
                    <TypeWriterEffect
                      startDelay={1000}
                      cursorColor="#3F3D56"
                      multiText={[
                        t("header.main_1"),
                        t("header.main_2"),
                        t("header.main_3"),
                        t("header.main_4"),
                        t("header.main_5"),
                      ]}
                      multiTextDelay={1000}
                      typeSpeed={30}
                      multiTextLoop
                    />
                  </Type>
                </div>
              </AnimationOnScroll>
            </div>
            <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={600}>
              <Subscribe />
            </AnimationOnScroll>
          </AnimationOnScroll>
        </CustomContainer>
      </HeaderBackground>
      <PinterestBackground>
        <CustomContainer>
          <div id="shoppers">
            <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={200}>
              <h2>{t("pinterest.title")}</h2>
            </AnimationOnScroll>
          </div>
          <PinterestLayout />
        </CustomContainer>
      </PinterestBackground>
      <GridBackground>
        <CustomContainer>
          <div id="creators">
            <AnimationOnScroll animateOnce animateIn="animate__fadeIn">
              <h2>{t("grid.title")}</h2>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={200}>
              <h4>{t("grid.subTitle")}</h4>
            </AnimationOnScroll>
          </div>
          <TwoColumns items={twoColumnsData} />
        </CustomContainer>
      </GridBackground>
      <ExamplesBackground>
        <CustomContainer>
          <Examples data={examplesData} title={t("examples.title")} subTitle={t("examples.subTitle")} />
        </CustomContainer>
      </ExamplesBackground>
      <StepsBackground id="brands">
        <CustomContainer>
          <Steps title={t("steps.title")} />
        </CustomContainer>
      </StepsBackground>
      <SubscribeBackground>
        <CustomContainer>
          <Col>
            <div id="brands">
              <AnimationOnScroll animateOnce animateIn="animate__fadeIn">
                <h2>{t("subscribe.title")}</h2>
              </AnimationOnScroll>
            </div>
            <AnimationOnScroll animateOnce animateIn="animate__fadeIn" delay={200}>
              <StyledButton onClick={() => handleShow()} text={t("subscribe.button")} textColor="#000" bgColor="#fff" />
            </AnimationOnScroll>
          </Col>
        </CustomContainer>
      </SubscribeBackground>
      <SubscribeModal show={show} handleClose={handleClose} handleShow={handleShow} />
    </MainLayout>
  )
}

export default Home
