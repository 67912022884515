import { Modal, Form } from "react-bootstrap"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import StyledButton from "./StyledButton"
import { useGlobalContext } from "../GlobalContext"

const gap = "24px"

const StyledModal = styled(Modal)`
  color: #000;

  .modal-content {
    border-radius: 20px;
  }

  .modal-header,
  .modal-body {
    padding: 30px;
  }

  .modal-header {
    border: none;
    padding-bottom: 0;

    h4 {
      font-size: 24px;
    }
  }
`

const StyledForm = styled.form`
  input,
  select {
    border: none;
    background-color: ${(props) => props.theme.modalInputBg};
    height: 49px;
    font-size: 14px;
    margin-bottom: ${gap};
    border-radius: 12px;

    ::placeholder {
      color: ${(props) => props.theme.modalInputPlaceholder};
    }

    :checked {
      background-color: #5b58f3;
      border-color: #5b58f3;
    }
  }

  .form-check {
    font-size: 14px;
    display: flex;
    margin-bottom: ${gap};
    position: relative;

    input {
      width: 20px;
      height: 20px;
      margin-top: 10px;
      position: absolute;
      top: calc(50% - 20px);
    }

    label {
      padding-left: 20px;
    }
  }

  button {
    max-width: 100%;
    height: 49px;
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: ${gap};
  grid-row-gap: 0px;
`

function SubscribeModal({ show, handleClose }) {
  const { t } = useTranslation()
  const { handleShow, email } = useGlobalContext()

  return (
    <StyledModal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("modal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledForm>
          <Wrapper>
            <Form.Group>
              <Form.Control type="first-name" placeholder={t("modal.first_name")} />
            </Form.Group>
            <Form.Group>
              <Form.Control type="last-name" placeholder={t("modal.last_name")} />
            </Form.Group>
          </Wrapper>
          <Form.Group>
            {t("i_am_a")}
            <Form.Select>
              <option value="1">{t("mainMenu.shopper")}</option>
              <option value="2">{t("mainMenu.creator")}</option>
              <option value="3">{t("mainMenu.brand")}</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Control type="email" placeholder={t("modal.email")} defaultValue={email || null} />
          </Form.Group>
          <Form.Group>
            <Form.Check type="checkbox" id="default-checkbox" label={t("modal.consent")} />
          </Form.Group>
          <StyledButton onClick={() => handleShow()} text={t("subscribe.button")} textColor="#fff" bgColor="#5B58F3" />
        </StyledForm>
      </Modal.Body>
    </StyledModal>
  )
}

export default SubscribeModal
